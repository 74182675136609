import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelSelect as _vModelSelect, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "mb-5 mb-xl-8 card" }
const _hoisted_2 = { class: "card-header border-0 pt-5" }
const _hoisted_3 = { class: "card-title align-items-start flex-column" }
const _hoisted_4 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_5 = { class: "text-muted mt-1 fw-bold fs-7" }
const _hoisted_6 = { class: "align-items-end flex-column" }
const _hoisted_7 = {
  href: "#",
  class: "btn btn-primary btn-light",
  "data-bs-toggle": "modal",
  "data-bs-target": "#kt_modal_1"
}
const _hoisted_8 = { class: "card-body py-3" }
const _hoisted_9 = { class: "p-5 mt-5 mb-5 bg-primary bg-opacity-25 rounded" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col" }
const _hoisted_12 = ["placeholder"]
const _hoisted_13 = { class: "col" }
const _hoisted_14 = ["placeholder"]
const _hoisted_15 = { class: "col" }
const _hoisted_16 = {
  disabled: "",
  selected: "",
  value: ""
}
const _hoisted_17 = { class: "col" }
const _hoisted_18 = {
  disabled: "",
  selected: "",
  value: ""
}
const _hoisted_19 = {
  name: "active",
  value: "ACT"
}
const _hoisted_20 = {
  name: "disabled",
  value: "DEA"
}
const _hoisted_21 = {
  name: "disabled",
  value: "INV"
}
const _hoisted_22 = { class: "col" }
const _hoisted_23 = { class: "table-responsive" }
const _hoisted_24 = { class: "table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3" }
const _hoisted_25 = { class: "fw-bolder text-muted" }
const _hoisted_26 = { class: "min-w-150px" }
const _hoisted_27 = { class: "min-w-120px" }
const _hoisted_28 = { class: "min-w-120px" }
const _hoisted_29 = { class: "min-w-120px" }
const _hoisted_30 = { class: "min-w-120px" }
const _hoisted_31 = { class: "min-w-100px text-end" }
const _hoisted_32 = { key: 0 }
const _hoisted_33 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary fs-6"
}
const _hoisted_34 = { class: "text-muted fw-bold text-muted d-block fs-7" }
const _hoisted_35 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
}
const _hoisted_36 = { class: "badge-light badge text-primary" }
const _hoisted_37 = {
  href: "#",
  class: "text-dark fw-bolder text-hover-primary d-block mb-1 fs-6"
}
const _hoisted_38 = { class: "text-muted fw-bold text-muted d-block fs-7" }
const _hoisted_39 = {
  key: 0,
  class: "badge-light-success badge text-success"
}
const _hoisted_40 = {
  key: 1,
  class: "badge-light-primary badge text-primary"
}
const _hoisted_41 = { class: "text-end" }
const _hoisted_42 = ["onClick"]
const _hoisted_43 = { key: 0 }
const _hoisted_44 = {
  class: "modal fade",
  tabindex: "-1",
  id: "kt_modal_1"
}
const _hoisted_45 = { class: "modal-dialog" }
const _hoisted_46 = { class: "modal-content" }
const _hoisted_47 = { class: "modal-header" }
const _hoisted_48 = { class: "modal-title" }
const _hoisted_49 = { class: "modal-body" }
const _hoisted_50 = {
  id: "kt_add_action_form",
  class: "form"
}
const _hoisted_51 = { class: "row mb-6" }
const _hoisted_52 = { class: "col-lg-12 col-form-label required fw-bold fs-6" }
const _hoisted_53 = { class: "col-lg-12" }
const _hoisted_54 = { class: "row" }
const _hoisted_55 = { class: "col-lg-12 fv-row" }
const _hoisted_56 = { class: "modal-footer" }
const _hoisted_57 = {
  type: "button",
  class: "btn btn-light",
  "data-bs-dismiss": "modal"
}
const _hoisted_58 = { class: "indicator-label" }
const _hoisted_59 = { class: "indicator-progress" }
const _hoisted_60 = { class: "indicator-validate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h3", _hoisted_3, [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("pages.users.title")), 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(this.$store.getters.currentSettings.clientName), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("a", _hoisted_7, _toDisplayString(_ctx.$t("pages.users.addUsers")), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter.identity) = $event)),
                type: "text",
                name: "fname",
                class: "form-control form-control-lg form-control-solid mb-3 mb-lg-0",
                placeholder: _ctx.$t('pages.users.name')
              }, null, 8, _hoisted_12), [
                [_vModelText, _ctx.filter.identity]
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filter.email) = $event)),
                type: "text",
                name: "lname",
                class: "form-control form-control-lg form-control-solid",
                placeholder: _ctx.$t('pages.users.email')
              }, null, 8, _hoisted_14), [
                [_vModelText, _ctx.filter.email]
              ])
            ]),
            _createElementVNode("div", _hoisted_15, [
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filter.user_type) = $event)),
                class: "form-control form-control-solid"
              }, [
                _createElementVNode("option", _hoisted_16, _toDisplayString(_ctx.$t("pages.users.userType")), 1),
                _cache[8] || (_cache[8] = _createElementVNode("option", {
                  name: "pilote",
                  value: "pilote"
                }, "Pilote", -1)),
                _cache[9] || (_cache[9] = _createElementVNode("option", {
                  name: "network",
                  value: "network"
                }, "Réseau baromètre", -1)),
                _cache[10] || (_cache[10] = _createElementVNode("option", {
                  name: "manager",
                  value: "manager"
                }, "Manager", -1))
              ], 512), [
                [_vModelSelect, _ctx.filter.user_type]
              ])
            ]),
            _createElementVNode("div", _hoisted_17, [
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filter.status) = $event)),
                class: "form-control form-control-solid"
              }, [
                _createElementVNode("option", _hoisted_18, _toDisplayString(_ctx.$t("pages.users.status")), 1),
                _createElementVNode("option", _hoisted_19, _toDisplayString(_ctx.$t("pages.users.active")), 1),
                _createElementVNode("option", _hoisted_20, _toDisplayString(_ctx.$t("pages.users.disabled")), 1),
                _createElementVNode("option", _hoisted_21, _toDisplayString(_ctx.$t("pages.users.invited")), 1)
              ], 512), [
                [_vModelSelect, _ctx.filter.status]
              ])
            ]),
            _createElementVNode("div", _hoisted_22, [
              _createElementVNode("button", {
                type: "submit",
                class: "btn btn-primary btn-light",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.fetchUsers()))
              }, _toDisplayString(_ctx.$t("general.search")), 1),
              _createElementVNode("button", {
                type: "submit",
                class: "ms-3 btn btn-icon btn-light btn-active-light-primary",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.resetFilter()))
              }, _cache[11] || (_cache[11] = [
                _createElementVNode("i", { class: "fas fa-redo fs-7 pt-1" }, null, -1)
              ]))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_23, [
          _createElementVNode("table", _hoisted_24, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", _hoisted_25, [
                _createElementVNode("th", _hoisted_26, _toDisplayString(_ctx.$t("pages.users.name")), 1),
                _createElementVNode("th", _hoisted_27, _toDisplayString(_ctx.$t("pages.users.email")), 1),
                _createElementVNode("th", _hoisted_28, _toDisplayString(_ctx.$t("pages.users.accessLevel")), 1),
                _createElementVNode("th", _hoisted_29, _toDisplayString(_ctx.$t("pages.users.lastConnection")), 1),
                _createElementVNode("th", _hoisted_30, _toDisplayString(_ctx.$t("pages.users.status")), 1),
                _createElementVNode("th", _hoisted_31, _toDisplayString(_ctx.$t("pages.users.actions")), 1)
              ])
            ]),
            (!_ctx.loading)
              ? (_openBlock(), _createElementBlock("tbody", _hoisted_32, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.users, (user) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      key: user.id
                    }, [
                      _createElementVNode("td", null, [
                        _createElementVNode("a", _hoisted_33, _toDisplayString(user.userprofile.firstname), 1),
                        _createElementVNode("span", _hoisted_34, _toDisplayString(user.userprofile.lastname), 1)
                      ]),
                      _createElementVNode("td", null, [
                        _createElementVNode("a", _hoisted_35, _toDisplayString(user.email), 1)
                      ]),
                      _createElementVNode("td", null, [
                        _createElementVNode("span", _hoisted_36, _toDisplayString(user.userprofile.user_type.label), 1)
                      ]),
                      _createElementVNode("td", null, [
                        _createElementVNode("a", _hoisted_37, _toDisplayString(_ctx.$moment(user.last_connected.date_created).format("DD/MM/YYYY")), 1),
                        _createElementVNode("span", _hoisted_38, _toDisplayString(_ctx.$moment(user.last_connected.date_created).format("HH:mm")), 1)
                      ]),
                      _createElementVNode("td", null, [
                        (user.status === 'ACT')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_39, "Actif"))
                          : _createCommentVNode("", true),
                        (user.status === 'INV')
                          ? (_openBlock(), _createElementBlock("span", _hoisted_40, "Invité"))
                          : _createCommentVNode("", true)
                      ]),
                      _createElementVNode("td", _hoisted_41, [
                        _createElementVNode("a", {
                          onClick: ($event: any) => (_ctx.editUser(user)),
                          href: "#",
                          class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                        }, _cache[12] || (_cache[12] = [
                          _createElementVNode("span", { class: "svg-icon svg-icon-3" }, [
                            _createElementVNode("svg", {
                              fill: "none",
                              viewBox: "0 0 24 24",
                              height: "24",
                              width: "24",
                              xmlns: "http://www.w3.org/2000/svg"
                            }, [
                              _createElementVNode("path", {
                                xmlns: "http://www.w3.org/2000/svg",
                                opacity: "0.3",
                                d: "M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z",
                                fill: "black"
                              }),
                              _createElementVNode("path", {
                                xmlns: "http://www.w3.org/2000/svg",
                                d: "M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z",
                                fill: "black"
                              })
                            ])
                          ], -1)
                        ]), 8, _hoisted_42)
                      ])
                    ]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_43, _cache[13] || (_cache[13] = [
                _createElementVNode("div", { class: "d-flex row-auto flex-center w-100 h-200px" }, [
                  _createElementVNode("span", {
                    class: "spinner-border text-primary",
                    role: "status"
                  })
                ], -1)
              ])))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_44, [
      _createElementVNode("div", _hoisted_45, [
        _createElementVNode("div", _hoisted_46, [
          _createElementVNode("div", _hoisted_47, [
            _createElementVNode("h5", _hoisted_48, _toDisplayString(_ctx.$t("pages.users.addUsers")), 1),
            _cache[14] || (_cache[14] = _createElementVNode("div", {
              class: "btn btn-icon btn-sm btn-active-light-primary ms-2",
              "data-bs-dismiss": "modal",
              "aria-label": "Close"
            }, [
              _createElementVNode("span", { class: "svg-icon svg-icon-2x" })
            ], -1))
          ]),
          _createElementVNode("div", _hoisted_49, [
            _createElementVNode("form", _hoisted_50, [
              _createElementVNode("div", _hoisted_51, [
                _createElementVNode("label", _hoisted_52, _toDisplayString(_ctx.$t("pages.users.file")), 1),
                _createElementVNode("div", _hoisted_53, [
                  _createElementVNode("div", _hoisted_54, [
                    _createElementVNode("div", _hoisted_55, [
                      _createElementVNode("input", {
                        onChange: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handleFile && _ctx.handleFile(...args))),
                        type: "file",
                        name: "avatar",
                        accept: ".xlsx"
                      }, null, 32)
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_56, [
            _createElementVNode("button", _hoisted_57, _toDisplayString(_ctx.$t("general.close")), 1),
            _createElementVNode("button", {
              type: "button",
              onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.addUsers && _ctx.addUsers(...args))),
              id: "kt_account_profile_details_submit",
              ref: "submitButton",
              class: "btn btn-primary"
            }, [
              _createElementVNode("span", _hoisted_58, _toDisplayString(_ctx.$t("pages.users.addUsers")), 1),
              _createElementVNode("span", _hoisted_59, [
                _createTextVNode(_toDisplayString(_ctx.$t("general.pleaseWait")) + " ", 1),
                _cache[15] || (_cache[15] = _createElementVNode("span", { class: "spinner-border spinner-border-sm align-middle ms-2" }, null, -1))
              ]),
              _createElementVNode("span", _hoisted_60, [
                _createTextVNode(_toDisplayString(_ctx.$t("general.done")) + " ", 1),
                _cache[16] || (_cache[16] = _createElementVNode("span", { class: "fas fa-check f-5 ms-3" }, null, -1))
              ])
            ], 512)
          ])
        ])
      ])
    ])
  ], 64))
}